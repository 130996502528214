*,
*::before,
*::after {
  box-sizing: border-box;
  /* font-family: "Source Sans Pro", sans-serif; */
}

:root {
  --accent-color: #39abe8;
}

body {
  margin: 0;
}

.full-screen-container {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/assets/login-bg.png'); */
  background-color: rgb(147, 147, 147);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  padding: 40px;
  width: calc(100% - 40px);
  max-width: 600px;
  border-radius: 20px;
  background: url('/public/wave.svg') no-repeat rgba(255, 255, 255, 0.7);
  /* background: url('/public/wave-orange.svg') no-repeat rgba(255, 255, 255, 0.7); */
  background-position: bottom;
  background-size: 200%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.login-container.fresaar {
  max-width: 700px;
}

.login-title {
  margin-top: 25px;
  color: black;
  text-align: center;
  font-weight: normal;
  background-color: transparent;
  padding-bottom: 1rem;
}

.login-title strong {
  font-size: 32px;
  letter-spacing: 1px;
}
.login-title p {
  font-size: 14px;
  margin: 0 auto;
  /* max-width: 80%; */
}

.logo {
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.login-button {
  font-size: 1.5rem;
  font-weight: lighter;
  background-color: hsl(var(--primary-hsl), 0.25);
  border: 1px solid hsl(var(--primary-hsl));
  border-radius: 0.25em;
  outline: none;
  cursor: pointer;
  color: white;
  position: relative;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.05em;
  border-radius: 0.8em;
  border: none;
  background: linear-gradient(to right, #e27e2d, #e02900);
  color: ghostwhite;
  overflow: hidden;
  width: 60%;
  margin: 20px auto 0;
}

.login-button:hover,
.login-button:focus {
  background-color: hsl(var(--primary-hsl), 0.4);
}

.cphi-card {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: black;
}

.cphi-card img {
  height: 30px;
  width: auto;
}
.cphi-card p {
  font-size: 11px;
  margin: 0;
}

.login-button span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
  display: inline-flex;
  align-items: center;
  padding-block: 12px;
}

.login-button::before,
.login-button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.login-button::before {
  content: '';
  background: #000;
  width: 120%;
  left: -10%;
  transform: skew(30deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.login-button:hover::before {
  transform: translate3d(100%, 0, 0);
}

.login-button:active {
  transform: scale(0.95);
}

.input-group {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  border: solid 1.5px #9e9e9e;
  border-radius: 1rem;
  background: none;
  padding: 1.1em;
  font-size: 14px;
  color: black;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 20px;
  background-color: whitesmoke;
}

.user-label {
  position: absolute;
  left: 15px;
  color: black;
  pointer-events: none;
  transform: translateY(1rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 12px;
}

.input:focus ~ label,
input:not(:placeholder-shown) ~ label {
  transform: translateY(-50%) scale(1);
  background-color: var(--accent-color);
  padding: 0.05em 0.7em;
  color: black;
  border-radius: 25em;
}

.input.error {
  border: 1.5px solid red;
}

.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  max-width: 300px;
}

.contactus {
  text-align: center;
  text-decoration: none;
  color: ghostwhite;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
  font-family: inherit;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.05em;
}

.contactus button:hover {
  /* background-color: #00385d; */
  background: linear-gradient(to right, #e27e2d, #e02900);
}

.contactus button {
  border-radius: 25em;
  border: none;
  background-color: #0099ff;
  overflow: hidden;
  color: ghostwhite;
  font-size: 20px;
  padding: 10px 20px;
  position: bottom;
}

.error-msg {
  text-align: center;
  margin-bottom: 25px;
  margin-top: 0;
  color: rgb(213, 0, 0);
}

@media only screen and (max-width: 900px) {
  .login-container {
    padding: 20px;
  }

  .login-button {
    padding: 5px 10px;
  }
}

.report-style-class {
  height: 100%;
  border: none;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.cphi-container {
  margin: 20px auto 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.cphi-container p {
  font-size: 10px;
  letter-spacing: 1px;
}
.doh-logo {
  width: 11%;
}
.koica1-logo {
  width: 32%;
}
.koica-logo {
  width: 17%;
}
.who-logo {
  width: 25%;
  height: 20%;
}
.cphi-logo {
  margin-top: -10px;
  width: 20%;
}